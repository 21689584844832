<template>
	<div class="wrap wrap--mypage">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
			<div class="container">
				<div class="mypage-container">
                    <!-- 배너 타이틀 -->
                    <div class="mypage-title">
                        <h1 class="text">마이페이지</h1>
                    </div>
					<!-- 네비게이션 메뉴 -->
					<nav class="mypage-navigation">
						<mypage-navigation></mypage-navigation>
					</nav>
					<!-- 페이지 콘텐츠 -->
					<div class="mypage-contents">
						<div class="mypage-contents__head">
                            <h2 class="mypage-contents__title">쿠폰내역</h2>
						</div>
						<div class="mypage-contents__body">
							<div role="table" class="board-table board-table--solid board-table--coupon">
								<div role="rowgroup">
									<div role="row">
										<div role="columnheader">쿠폰명</div>
										<div role="columnheader">사용기간</div>
										<div role="columnheader">남은일자</div>
									</div>
								</div>
								<div role="rowgroup">
									<div v-for="coupon in coupons" role="row">
										<div role="cell"><b class="weight-5">{{ coupon.name }}</b></div>
										<div role="cell">
											<span v-if="coupon.expiration.enabled">{{ coupon.expiration.startDate }} ~ {{ coupon.expiration.endDate }}</span>
										</div>
										<div role="cell">
											<span v-if="coupon.expiration.enabled">{{ new Date(new Date(coupon.expiration.endDate) - new Date(coupon.expiration.startDate)).getDate() }}일</span>
										</div>
									</div>
								</div>
							</div>
							<div v-if="more" class="bottom-button">
								<button class="button button--border-primary"><span>더보기</span></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from "../../../components/client/inc/main-header.vue";
import MainFooter from "../../../components/client/inc/main-footer.vue";
import MypageNavigation from "../../../components/client/mypage-navigation.vue";

export default{
	components: {
		MainHeader,
		MainFooter,
		MypageNavigation
	},
	data: () => {
		return {
			page: 1,
			pageCount: 0,
			limit: 5,

			coupons: [],
			more: true,
		}
	},
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		async init() {
			try{
				await this.getCoupons();
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message.toString());
			}
		},

		getCoupons(){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.get('/api/v1/me/coupons');

					res.data.coupons.forEach((coupon) => this.coupons.push(coupon));
					this.more = (this.coupons.length == res.data.summary.totalCount) ? false : true;

					resolve();
				}
				catch(error){
					reject(error);
				}
			});
		}
	},
}
</script>
