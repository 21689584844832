var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--mypage"
  }, [_c('main-header'), _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "mypage-container"
  }, [_vm._m(0), _c('nav', {
    staticClass: "mypage-navigation"
  }, [_c('mypage-navigation')], 1), _c('div', {
    staticClass: "mypage-contents"
  }, [_vm._m(1), _c('div', {
    staticClass: "mypage-contents__body"
  }, [_c('div', {
    staticClass: "board-table board-table--solid board-table--coupon",
    attrs: {
      "role": "table"
    }
  }, [_vm._m(2), _c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, _vm._l(_vm.coupons, function (coupon) {
    return _c('div', {
      attrs: {
        "role": "row"
      }
    }, [_c('div', {
      attrs: {
        "role": "cell"
      }
    }, [_c('b', {
      staticClass: "weight-5"
    }, [_vm._v(_vm._s(coupon.name))])]), _c('div', {
      attrs: {
        "role": "cell"
      }
    }, [coupon.expiration.enabled ? _c('span', [_vm._v(_vm._s(coupon.expiration.startDate) + " ~ " + _vm._s(coupon.expiration.endDate))]) : _vm._e()]), _c('div', {
      attrs: {
        "role": "cell"
      }
    }, [coupon.expiration.enabled ? _c('span', [_vm._v(_vm._s(new Date(new Date(coupon.expiration.endDate) - new Date(coupon.expiration.startDate)).getDate()) + "일")]) : _vm._e()])]);
  }), 0)]), _vm.more ? _c('div', {
    staticClass: "bottom-button"
  }, [_vm._m(3)]) : _vm._e()])])])])]), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mypage-title"
  }, [_c('h1', {
    staticClass: "text"
  }, [_vm._v("마이페이지")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mypage-contents__head"
  }, [_c('h2', {
    staticClass: "mypage-contents__title"
  }, [_vm._v("쿠폰내역")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("쿠폰명")]), _c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("사용기간")]), _c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("남은일자")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "button button--border-primary"
  }, [_c('span', [_vm._v("더보기")])]);

}]

export { render, staticRenderFns }